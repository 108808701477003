<template>
    <TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="group_name"
        initSortOrder="asc"
        :filters="filters"
        addButtonName="Add User Group">

        <template v-slot:form_items="{formdata}">
            <el-form-item label="User Group Name" prop="group_name">
                <el-input v-model="formdata.group_name" maxlength="120" minlength="5" />
            </el-form-item>

            <div class="ug-form-fa-row">
                <div>Full Access</div>
                <div><el-checkbox v-model="formdata.full_access" /></div>
            </div>


            <div v-if="formdata.access && !formdata.full_access" class="group_access_rows">
                <div class="ug-form-row">
                    <div class="ug_ar-title">Users & Groups</div>
                    <div class="ug_ar-checkbox"><div>view</div><el-checkbox v-model="formdata.access['get-users']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>add</div><el-checkbox v-model="formdata.access['add-users']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>edit</div><el-checkbox v-model="formdata.access['edit-users']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>delete</div><el-checkbox v-model="formdata.access['delete-users']"></el-checkbox></div>
                </div>
                <div class="ug-form-row">
                    <div class="ug_ar-title">Clients</div>
                    <div class="ug_ar-checkbox"><div>view</div><el-checkbox v-model="formdata.access['get-clients']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>add</div><el-checkbox v-model="formdata.access['add-clients']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>edit</div><el-checkbox v-model="formdata.access['edit-clients']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>delete</div><el-checkbox v-model="formdata.access['delete-clients']"></el-checkbox></div>
                </div><div class="ug-form-row">
                    <div class="ug_ar-title">Currency</div>
                    <div class="ug_ar-checkbox"><div>view</div><el-checkbox v-model="formdata.access['get-currency']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>add</div><el-checkbox v-model="formdata.access['add-currency']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>edit</div><el-checkbox v-model="formdata.access['edit-currency']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>delete</div><el-checkbox v-model="formdata.access['delete-currency']"></el-checkbox></div>
                </div><div class="ug-form-row">
                    <div class="ug_ar-title">Currency Rates</div>
                    <div class="ug_ar-checkbox"><div>view</div><el-checkbox v-model="formdata.access['get-currency-rates']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"></div>
                    <div class="ug_ar-checkbox"></div>
                    <div class="ug_ar-checkbox"></div>
                </div><div class="ug-form-row">
                    <div class="ug_ar-title">ISINs and RICs</div>
                    <div class="ug_ar-checkbox"><div>view</div><el-checkbox v-model="formdata.access['get-isin']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>add</div><el-checkbox v-model="formdata.access['add-isin']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>edit</div><el-checkbox v-model="formdata.access['edit-isin']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>delete</div><el-checkbox v-model="formdata.access['delete-isin']"></el-checkbox></div>
                </div><div class="ug-form-row">
                    <div class="ug_ar-title">ISINs Fields</div>
                    <div class="ug_ar-checkbox"><div>view</div><el-checkbox v-model="formdata.access['get-isin-fields']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>add</div><el-checkbox v-model="formdata.access['add-isin-fields']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>edit</div><el-checkbox v-model="formdata.access['edit-isin-fields']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>delete</div><el-checkbox v-model="formdata.access['delete-isin-fields']"></el-checkbox></div>
                </div><div class="ug-form-row">
                    <div class="ug_ar-title">Dictionaries</div>
                    <div class="ug_ar-checkbox"><div>view</div><el-checkbox v-model="formdata.access['get-dict']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>add</div><el-checkbox v-model="formdata.access['add-dict']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>edit</div><el-checkbox v-model="formdata.access['edit-dict']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>delete</div><el-checkbox v-model="formdata.access['delete-dict']"></el-checkbox></div>
                </div><div class="ug-form-row">
                    <div class="ug_ar-title">Portfolio Management</div>
                    <div class="ug_ar-checkbox"><div>view</div><el-checkbox v-model="formdata.access['get-portf-settings']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>add</div><el-checkbox v-model="formdata.access['add-portf-settings']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>edit</div><el-checkbox v-model="formdata.access['edit-portf-settings']"></el-checkbox></div>
                    <div class="ug_ar-checkbox"><div>delete</div><el-checkbox v-model="formdata.access['delete-portf-settings']"></el-checkbox></div>
                </div>
            </div>
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name:'usergroups',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
            module: 'userGroups',
            form_initial: {
                full_access : false,
                access : {
                    'get-user-groups'       : false,
                    'add-user-groups'       : false,
                    'edit-user-groups'      : false,
                    'delete-user-groups'    : false,
                    'get-users'             : false,
                    'add-users'             : false,
                    'edit-users'            : false,
                    'delete-users'          : false,
                    'get-isin'              : false,
                    'add-isin'              : false,
                    'edit-isin'             : false,
                    'delete-isin'           : false,
                    'get-isin-fields'       : false,
                    'add-isin-fields'       : false,
                    'edit-isin-fields'      : false,
                    'delete-isin-fields'    : false,
                    'get-dict'              : false,
                    'add-dict'              : false,
                    'edit-dict'             : false,
                    'delete-dict'           : false,
                    'get-clients'           : false,
                    'add-clients'           : false,
                    'edit-clients'          : false,
                    'delete-clients'        : false,
                    'get-currency'          : false,
                    'add-currency'          : false,
                    'edit-currency'         : false,
                    'delete-currency'       : false,
                    'get-currency-rates'    : false,
                }
            },
            formRules: {
                group_name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { min: 5, message: 'this field should have min 5 characters', trigger: 'blur' },
                    { max: 120, message: 'this field should have max 120 characters', trigger: 'blur' },
                ]
            },
            tableColumns: [
                {
                    field    : 'group_name', 
                    title    : 'Group Name', 
                    minWidth : 200, 
                    fixed    : 'left',
                    params   : {
                        filter   : 'input', 
                    }
                },
            ],
            filters: {
                group_name : { value: undefined },
            }
        }
    },

    computed: {
        ...mapState({
            user: state => state.app.user,
        }),

        rights(){
            return {
                view : (this.user && this.user.rights) ? this.user.rights['get-users'] : false,
                edit : (this.user && this.user.rights) ? this.user.rights['add-users'] : false,
                add  : (this.user && this.user.rights) ? this.user.rights['edit-users'] : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-users'] : false,
            }
        }
    },
}
</script>
